var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "page-content",
        [
          _c("h1", { staticStyle: { "margin-bottom": "30px" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.query.type === "edit"
                    ? "编辑账号"
                    : _vm.query.type === "show"
                    ? "查看账号"
                    : "新增账号"
                ) +
                " "
            ),
          ]),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.ruleForm,
                rules: _vm.rules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户名(手机号)", prop: "userMobile" } },
                [
                  _c("el-input", {
                    staticClass: "width360",
                    attrs: {
                      size: "mini",
                      disabled: _vm.$route.query.type !== "add",
                      maxlength: "11",
                      "show-word-limit": "",
                      placeholder: "请输入手机号",
                    },
                    model: {
                      value: _vm.ruleForm.userMobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "userMobile", _vm._n($$v))
                      },
                      expression: "ruleForm.userMobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "真实姓名", prop: "userRealName" } },
                [
                  _c("el-input", {
                    staticClass: "width360",
                    attrs: {
                      size: "mini",
                      maxlength: "5",
                      "show-word-limit": "",
                      placeholder: "请输入真实姓名",
                    },
                    model: {
                      value: _vm.ruleForm.userRealName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "userRealName", $$v)
                      },
                      expression: "ruleForm.userRealName",
                    },
                  }),
                ],
                1
              ),
              _vm.$route.query.type === "add"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "密码", prop: "userPassword" } },
                    [
                      _c("el-input", {
                        staticClass: "width360",
                        attrs: {
                          size: "mini",
                          maxlength: "8",
                          minlength: "6",
                          "show-word-limit": "",
                          placeholder: "请输入密码",
                        },
                        model: {
                          value: _vm.ruleForm.userPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "userPassword", $$v)
                          },
                          expression: "ruleForm.userPassword",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "角色名称", prop: "roleIdList" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "width360",
                      attrs: {
                        placeholder: "请选择角色名称",
                        size: "mini",
                        multiple: "",
                      },
                      model: {
                        value: _vm.ruleForm.roleIdList,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "roleIdList", $$v)
                        },
                        expression: "ruleForm.roleIdList",
                      },
                    },
                    _vm._l(_vm.roleArr, function (item, i) {
                      return _c("el-option", {
                        key: i,
                        attrs: { label: item.roleName, value: item.roleId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联店铺", prop: "storeIdList" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "width360",
                      attrs: {
                        placeholder: "请选择店铺名称",
                        size: "mini",
                        multiple: "",
                      },
                      model: {
                        value: _vm.ruleForm.storeIdList,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "storeIdList", $$v)
                        },
                        expression: "ruleForm.storeIdList",
                      },
                    },
                    _vm._l(_vm.storeArr, function (item, i) {
                      return _c("el-option", {
                        key: i,
                        attrs: { label: item.storeName, value: item.storeId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联自提门店", prop: "selfTemplateId" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.ruleForm.selfTemplateName || "未关联") +
                      " "
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "10px" },
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleCombineShop("selfTemplate")
                            },
                          },
                        },
                        [_vm._v(" 点击关联 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "button", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleAddShop("selfTemplate")
                            },
                          },
                        },
                        [_vm._v(" 新增自提门店 ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联线下门店", prop: "offlineShopId" } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.ruleForm.offlineShopName || "未关联") + " "
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "8px" },
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleCombineShop("offlineShop")
                            },
                          },
                        },
                        [_vm._v(" 点击关联 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "button", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleAddShop("offlineShop")
                            },
                          },
                        },
                        [_vm._v(" 新增线下门店 ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm.$route.query.type !== "read"
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.submitForm("ruleForm")
                            },
                          },
                        },
                        [_vm._v(" 保存 ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.shopListDialogTitle,
                visible: _vm.isShowShopsDialog,
                width: "600px",
                "before-close": () => {
                  _vm.isShowShopsDialog = false
                },
              },
              on: {
                "update:visible": function ($event) {
                  _vm.isShowShopsDialog = $event
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.selectedShop,
                        callback: function ($$v) {
                          _vm.selectedShop = $$v
                        },
                        expression: "selectedShop",
                      },
                    },
                    _vm._l(_vm.shopList, function (item) {
                      return _c(
                        "el-radio",
                        {
                          key: item.id,
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                          attrs: { label: item },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "margin-bottom": "20px" } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-bottom": "8px" } },
                                [
                                  _vm._v(
                                    " 门店名称：" + _vm._s(item.name) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-bottom": "8px" } },
                                [
                                  _vm._v(
                                    " 联系方式：" + _vm._s(item.phone) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "margin-bottom": "8px" } },
                                [
                                  _vm._v(
                                    " 门店地址：" + _vm._s(item.address) + " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("span", {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }