<template>
  <div>
    <page-content>
      <h1 style="margin-bottom: 30px">
        {{ query.type==='edit'?'编辑账号':(
          query.type==='show'?'查看账号':'新增账号'
        ) }}
      </h1>
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="130px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="用户名(手机号)"
          prop="userMobile"
        >
          <el-input
            v-model.number="ruleForm.userMobile"
            class="width360"
            size="mini"
            :disabled="$route.query.type !== 'add'"
            maxlength="11"
            show-word-limit
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="真实姓名"
          prop="userRealName"
        >
          <el-input
            v-model="ruleForm.userRealName"
            class="width360"
            size="mini"
            maxlength="5"
            show-word-limit
            placeholder="请输入真实姓名"
          ></el-input>
        </el-form-item>

        <el-form-item
          v-if="$route.query.type === 'add'"
          label="密码"
          prop="userPassword"
        >
          <el-input
            v-model="ruleForm.userPassword"
            class="width360"
            size="mini"
            maxlength="8"
            minlength="6"
            show-word-limit
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="角色名称"
          prop="roleIdList"
        >
          <el-select
            v-model="ruleForm.roleIdList"
            class="width360"
            placeholder="请选择角色名称"
            size="mini"
            multiple
          >
            <el-option
              v-for="(item, i) in roleArr"
              :key="i"
              :label="item.roleName"
              :value="item.roleId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="关联店铺"
          prop="storeIdList"
        >
          <el-select
            v-model="ruleForm.storeIdList"
            class="width360"
            placeholder="请选择店铺名称"
            size="mini"
            multiple
          >
            <el-option
              v-for="(item, i) in storeArr"
              :key="i"
              :label="item.storeName"
              :value="item.storeId"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="关联自提门店"
          prop="selfTemplateId"
        >
          {{ ruleForm.selfTemplateName || '未关联' }}
          <div>
            <el-button
              type="primary"
              size="small"
              style="margin-right: 10px;"
              @click="handleCombineShop('selfTemplate')"
            >
              点击关联
            </el-button>
            <el-button
              type="button"
              size="small"
              @click="handleAddShop('selfTemplate')"
            >
              新增自提门店
            </el-button>
          </div>
        </el-form-item>
        <el-form-item
          label="关联线下门店"
          prop="offlineShopId"
        >
          {{ ruleForm.offlineShopName || '未关联' }}
          <div>
            <el-button
              type="primary"
              size="small"
              style="margin-right: 8px;"
              @click="handleCombineShop('offlineShop')"
            >
              点击关联
            </el-button>
            <el-button
              type="button"
              size="small"
              @click="handleAddShop('offlineShop')"
            >
              新增线下门店
            </el-button>
          </div>
        </el-form-item>
        <el-form-item v-if="$route.query.type !== 'read'">
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
          >
            保存
          </el-button>
        </el-form-item>
      </el-form>
      <el-dialog
        :title="shopListDialogTitle"
        :visible.sync="isShowShopsDialog"
        width="600px"
        :before-close="() => {
          isShowShopsDialog = false
        }"
      >
        <div v-loading="loading">
          <el-radio-group
            v-model="selectedShop"
          >
            <el-radio
              v-for="(item) in shopList"
              :key="item.id"
              :label="item"
              style="display:flex; align-items: center;"
            >
              <div style=" margin-bottom: 20px;">
                <div style="margin-bottom: 8px;">
                  门店名称：{{ item.name }}
                </div>
                <div style="margin-bottom: 8px;">
                  联系方式：{{ item.phone }}
                </div>
                <div style="margin-bottom: 8px;">
                  门店地址：{{ item.address }}
                </div>
              </div>
            </el-radio>
          </el-radio-group>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
        >
        </span>
      </el-dialog>
    </page-content>
  </div>
</template>

<script>
export default {
  name: 'ExtractDetail',
  data() {
    return {
      // 选择门店弹窗
      isShowShopsDialog: false,
      shopListDialogTitle: '',
      type: '',
      shopList: [],
      loading: false,
      selectedShop: {
        id: '',
        name: ''
      },

      query: this.$route.query,
      suppliersId: '',
      storeId: '',
      ruleForm: {
        userMobile: '',
        userRealName: '',
        userPassword: null,
        roleIdList: [],
        storeIdList: [],
        offlineShopId: '',
        offlineShopName: '',
        selfTemplateId: '',
        selfTemplateName: ''
      },
      couponList: [], // 优惠券列表
      rules: {
        userMobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
          // {
          //   pattern: /^0{0,1}(13[0-9]|(17[0-9])|15[0-9]|18[0-9])[0-9]{8}$/,
          //   message: '手机号格式不对',
          //   trigger: 'blur'
          // }
        ],
        userRealName: [
          { required: true, message: '请输入真实姓名', trigger: 'change' }
        ],
        userPassword: [
          { required: true, message: '请输入密码', trigger: 'change' },
          { min: 6, max: 8, message: '长度在 6 到 8 个字符' }
        ],
        roleIdList: [
          { required: true, message: '请选择角色名称', trigger: 'change' }
        ],
        storeIdList: [
          { required: true, message: '请选择店铺名称', trigger: 'change' }
        ]
      },
      roleArr: [],
      storeArr: [],
      pagination: {
        currentPage: 1,
        nowPageSize: 100,
        count: 0
      }
    }
  },

  watch: {
    'selectedShop': {
      handler: function(val, oldVal) {
        if (this.type === 'offlineShop') {
          this.ruleForm.offlineShopId = val.id
          this.ruleForm.offlineShopName = val.name
        }
        if (this.type === 'selfTemplate') {
          this.ruleForm.selfTemplateId = val.id
          this.ruleForm.selfTemplateName = val.name
        }
      }
    }
  },
  mounted() {
    if (this.$route.query.type === 'edit') {
      this.getInfo()
    }
    this.getUserInfo()
    this.getRoleList()
    this.getStoreList()
  },
  methods: {
    // 关联线下门店
    handleCombineShop(type) {
      this.type = type
      this.isShowShopsDialog = true
      this.loading = true

      // 线下门店
      if (type === 'offlineShop') {
        this.shopListDialogTitle = '关联线下门店'
        const { offlineShopId, offlineShopName } = this.ruleForm

        this.$axios
          .get(this.$api.tradeShop.list, {
            params: {

            }
          })
          .then(res => {
            this.loading = false
            if (res.code === 0) {
              const resp = res.data || []
              const data = resp.map((item) => {
                return {
                  id: item.id,
                  name: item.name,
                  phone: item.moblie,
                  address: item.address
                }
              })
              this.shopList = data

              data.forEach((item) => {
                if (item.id === offlineShopId) {
                  this.selectedShop = item
                }
              })
            }
          })
          .catch(err => {
            this.loading = false
            console.log(err);
          })
        return
      }

      // 自提门店
      if (type === 'selfTemplate') {
        this.shopListDialogTitle = '关联自提门店'
        const { selfTemplateId } = this.ruleForm
        this.$axios
          .get(this.$api.trade_template_mail.list, {
            params: {
              templateType: 3,
              storeId: this.storeId
            }
          })
          .then(res => {
            this.loading = false
            if (res.code === 0) {
              const resp = res.data || []
              const data = resp.map((item) => {
                return {
                  id: item.id,
                  name: item.selfName,
                  phone: item.phone,
                  address: item.address
                }
              })

              this.shopList = data

              data.forEach((item) => {
                if (item.id === selfTemplateId) {
                  this.selectedShop = item
                }
              })
            }
          })
          .catch(err => {
            this.loading = false
            console.log(err);
          })
      }
    },
    // 新增线下门店
    handleAddShop(type) {
      if (type === 'offlineShop') {
        const url = `/nb/offlineStore/detail?type=add&suppliersId=${this.suppliersId}&storeId=${this.storeId}`
        window.open(url, '_blank')
      }
      if (type === 'selfTemplate') {
        const url = `/nb/goods/freight/self?type=add&storeId=${this.storeId}`
        window.open(url, '_blank')
      }
    },
    getUserInfo() {
      this.$axios({
        method: 'post',
        url: this.$api.back_supplier.view
      })
        .then((res) => {
          if (res.code !== 0) {
            this.$message(res.error_msg)
            return Promise.reject(res)
          }
          return res
        })
        .then(res => {
          const { suppliersId, storeList = [] } = res.data
          this.suppliersId = suppliersId
          this.storeId = storeList[0].storeId
        })
    },
    // 获取账号信息
    getInfo() {
      this.$axios({
        method: 'get',
        url: this.$api.admin.view,
        params: {
          mobile: this.query.id
        }
      }).then((res) => {
        if (res.code === 0) {
          let { relationShopDTO } = res.data

          relationShopDTO = relationShopDTO || {}
          this.ruleForm = {
            userMobile: res.data.userMobile,
            userRealName: res.data.userRealName,
            roleIdList: res.data.roleIdList,
            storeIdList: res.data.storeIdList,
            userId: res.data.userId,
            offlineShopId: relationShopDTO.offlineShopId || '',
            offlineShopName: relationShopDTO.offlineShopName || '',
            selfTemplateId: relationShopDTO.selfTemplateId || '',
            selfTemplateName: relationShopDTO.selfTemplateName || ''

          }
        } else {
          this.$message(res.error_msg)
        }
      })
    },
    getRoleList() {
      this.$axios({
        method: 'post',
        url: this.$api.role.list,
        data: {
          currentPage: this.pagination.currentPage,
          pageSize: this.pagination.nowPageSize
        }
      }).then((res) => {
        if (res.code === 0) {
          this.roleArr = res.data.records
        }
      })
    },
    getStoreList() {
      this.$axios({
        method: 'post',
        url: this.$api.store.list,
        data: {
          currentPage: 1,
          pageSize: 10000
        }
      }).then((res) => {
        if (res.code === 0) {
          this.storeArr = res.data.records
        }
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('确定保存账号信息吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let data = this.ruleForm
            if (this.$route.query.type === 'edit') {
              delete data.userMobile
              delete data.userPassword
            }
            this.$axios.post(this.$route.query.type === 'edit' ? this.$api.admin.edit : this.$api.admin.add, data).then(res => {
              if (res.code === 0) {
                this.$message.success('保存成功')
                this.$router.go(-1)
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            })
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 权限选择
    changePermission(data) {
      console.log(data)
      this.ruleForm.permission = data
    }
  }
}
</script>
<style scoped>
.width360 {
  width: 360px;
}
</style>
